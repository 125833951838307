var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-stepper',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"pb-5"},[_c('b-row',{},[(_vm.loaded)?_c('b-col',{staticClass:"col-12 text-center"},[_c('header-preview-steps',{attrs:{"data_header":{
              images: _vm.proposal.images,
              title: _vm.proposal.title,
              description: _vm.proposal.description,
              work_type: _vm.proposal.work_type,
              content_type: _vm.proposal.content_type
            }}}),_c('analytics-preview',{attrs:{"network":{
            followers: _vm.proposal.user_network.followers,
            er: _vm.proposal.user_network.er,
            username: _vm.proposal.user_network.username
          }}}),_c('dealsOptionsRequests',{staticClass:"mt-2",attrs:{"deals":_vm.proposal.deals,"options":_vm.proposal.options,"requests":_vm.proposal.requests}})],1):_vm._e()],1),_c('div',{staticClass:"container-nav-fixed"},[_c('b-button',{staticClass:"button-free-report mr-1",attrs:{"variant":"light"},on:{"click":_vm.editButton}},[_vm._v("Edit")]),_c('b-button',{staticClass:"button-free-report",attrs:{"variant":"primary","disabled":""},on:{"click":function($event){return _vm.$router.push({name: ''})}}},[_vm._v("Submit for review")])],1)],1),_c('modal-brandme',{key:_vm.update_edit_modal,attrs:{"show_modal":_vm.open_edit_modal},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('strong',[_vm._v("Select step to edit")])]},proxy:true},{key:"body",fn:function(){return _vm._l((_vm.getEditAvaible()),function(edit_option,index){return _c('div',{key:index,class:("lists-edit-steps " + (_vm.getEditAvaible()[index].disabled ? 'disabled-list-edit' : '')),on:{"click":function($event){!_vm.getEditAvaible()[index].disabled ? _vm.$router.push({name: edit_option.route, params: {slug: _vm.proposal.slug}}) : ''}}},[_c('div',[_vm._v(" "+_vm._s(edit_option.name)+" ")]),_c('div',[_c('feather-icon',{class:edit_option.exists ? 'text-success' : 'text-secondary',attrs:{"icon":edit_option.exists ? 'CheckCircleIcon' : 'CircleIcon'}})],1)])})},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }