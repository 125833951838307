<template>
  <b-row>
    <strong class="col-12 my-1 text-left">About @{{network.username}}</strong>
    <b-col>
      <div class="border-box-analytics">
        <p>{{$t('campaigns.followers').toUpperCase()}}</p>
        <strong>{{network.followers !== 0 ? utils.getFormat(network.followers) : '--'}}</strong >
      </div>
    </b-col>
    <b-col>
      <div class="border-box-analytics">
        <p>ENGAGEMENT</p>
        <strong>{{network.er !== null ? `${network.er} %` : '--'}}</strong >
      </div>
    </b-col>
    <b-col>
      <div class="border-box-analytics">
        <p>AVG. ENG. PER POST</p>
        <strong>N/A</strong >
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,

} from 'bootstrap-vue';
import utils from '@/libs/utils';

export default {
  name: 'analyticsPreview',
  components: {
    BRow,   
    BCol,
  },
  data() {
    return {
      utils,
    }
  },
  props: {
    network: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  methods: {
  }
}
</script>
<style>
.border-box-analytics {
  text-align: left;
  border: 1px solid #eaeaea;
  border-radius: 1em;
  padding: 1em;
  box-shadow: 0rem 0.375rem 0.75rem rgba(108 117 125 / 30%);
}
</style>
