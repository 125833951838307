<template>
  <layout-stepper>
    <template #body>
      <div class="pb-5">
        <b-row class="">
          <b-col class="col-12 text-center" v-if="loaded">
            <header-preview-steps 
              :data_header="{
                images: proposal.images,
                title: proposal.title,
                description: proposal.description,
                work_type: proposal.work_type,
                content_type: proposal.content_type
              }"
            />
            <analytics-preview :network="{
              followers: proposal.user_network.followers,
              er: proposal.user_network.er,
              username: proposal.user_network.username
            }"/>
            <dealsOptionsRequests 
              class="mt-2" 
              :deals="proposal.deals"
              :options="proposal.options"
              :requests="proposal.requests"
            />
          </b-col>
        </b-row>
        <div class="container-nav-fixed">
          <b-button variant="light" class="button-free-report mr-1" @click="editButton">Edit</b-button>
          <b-button variant="primary" class="button-free-report" @click="$router.push({name: ''})" disabled>Submit for review</b-button>
        </div>
      </div>
      <modal-brandme :show_modal="open_edit_modal" :key="update_edit_modal">
        <template #title>
          <strong>Select step to edit</strong>
        </template>
        <template #body>
          <div 
            :class="`lists-edit-steps ${getEditAvaible()[index].disabled ? 'disabled-list-edit' : ''}`" 
            v-for="(edit_option, index) in getEditAvaible()" 
            :key="index"
            @click="!getEditAvaible()[index].disabled ? $router.push({name: edit_option.route, params: {slug: proposal.slug}}) : ''"
          >
            <div>
              {{edit_option.name}}
            </div>
            <div>
              <feather-icon :icon="edit_option.exists ? 'CheckCircleIcon' : 'CircleIcon'" :class="edit_option.exists ? 'text-success' : 'text-secondary'"></feather-icon>
            </div>
          </div>
        </template>
      </modal-brandme>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/creator/layoutStepper.vue';
import utils from '@/libs/utils';
import creator_services from '@/services/creator';
import headerPreviewSteps from '@/views/components/creator/headerPreviewSteps.vue';
import analyticsPreview from '@/views/components/creator/analyticsPreview.vue';
import dealsOptionsRequests from '@/views/components/creator/dealsOptionsRequests.vue';
import modalBrandme from '@/views/components/modal/ModalBrandme.vue'

export default {
  name: 'preview',
  components: {
    BRow,   
    BCol,
    BButton,
    layoutStepper,
    headerPreviewSteps,
    analyticsPreview,
    dealsOptionsRequests,
    modalBrandme,
  },
  data() {
    return {
      utils,
      proposal: {
        images: [],
        title: '',
        description: '',
        work_type: '',
        content_type: '',
        options: [],
        requests: []
      },
      loaded: false,
      open_edit_modal: false,
      update_edit_modal: false,
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      creator_services.detailProposal(this.$route.params.slug).then((response) => {
        this.proposal = response;
        this.loaded = true;
      });
    },
    editButton() {
      this.open_edit_modal = true; 
      this.update_edit_modal = !this.update_edit_modal;
      localStorage.removeItem('steps');
    },
    getEditAvaible() {
      return [
        {name: 'Category', route: 'step_category', exists: true, disabled: true},
        {name: 'Sub category', route: 'step_subcategory', exists: true},
        {name: 'Connection', route: 'step_connection', exists: true, disabled: true},
        {name: 'Title', route: 'step_title', exists: true},
        {name: 'Description', route: 'step_description', exists: true},
        {name: 'Location', route: 'step_location', exists: true},
        {name: 'Deals', route: 'step_deals', exists: true},
        {name: 'Samples', route: 'step_samples', exists: true, disabled: true},
        {name: 'Options', route: 'step_options', exists: this.proposal.options.length > 0},
        {name: 'Requests', route: 'step_requests', exists: this.proposal.requests.length > 0},
      ]
    }
  },
}
</script>
<style>
.form-group-layout > label {
  text-align: left;
}
.btn-group-toggle {
  display:initial;
}
.container-nav-fixed {
  width: 100%;
  height: 80px;
  position: fixed;
  background-color: white;
  left: 0;
  bottom: 0;
  padding: 0 6em;
  display: flex;
  justify-content: end;
  align-content: center;
  box-shadow: 0 -2px 5px rgb(0 0 0 / 5%);
  z-index: 10;
}
.button-free-report {
  height: 40px;
  margin-top: 1em;
}
.lists-edit-steps {
  padding: 1em;
  border-bottom: 1px solid #dedede;
  display: flex;
  justify-content: space-between;
}
.lists-edit-steps:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.disabled-list-edit {
  cursor: initial !important;
  color: #dedede;
}
.disabled-list-edit:hover {
  background-color: white;
}
</style>