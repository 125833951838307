<template>
  <b-row class="mt-3 px-1">
    <div class="w-100 text-left">
      <strong class="my-1 text-left">Deals</strong>
      <p>The specific deliverables available from this Seller. Choose one.</p>
    </div>
    <b-form-radio-group v-model="selected_deal_radio" class="w-100">
      <b-col 
        class="card-radion-button-steps col-12 mb-1" 
        v-for="(deal, index) in deals" 
        :key="index"
        ref="card_radio_button"
        @click="getActiveDeal(deal, index)"
      >
        <div class="background-left-proposal-radios"/>
        <div class="text-left text-gray-600">
          <div class="float-right d-flex">
            <strong class="mr-1">${{utils.separatebycomma(deal.price)}}</strong>
            <b-form-radio :value="`deal_${index}`"></b-form-radio>
          </div>
          <strong class="w-100 mb-1 d-block">{{deal.title}}</strong>
          <p class="d-flex">
            <span class="d-flex align-items-center mr-1">
              <feather-icon icon="CalendarIcon" class="mr-05"/>
              {{deal.days_delivery}} Days delivery
            </span>
            <span class="d-flex align-items-center mr-1">
              <feather-icon icon="RepeatIcon" class="mr-05"/>
              {{deal.revision}} Revision
            </span>
            <span class="d-flex align-items-center mr-1">
              <b-icon icon="shield-check" class="mr-05"/>
              {{$t('creator.licensing').find((licence) => licence.value === deal.licensing).text}}
            </span>
          </p>
          <p>{{deal.description}}</p>
          <hr class="mb-1"/>
          <strong class="w-100 mb-1 d-block">Feaures</strong>
          <b-badge v-for="(feature, index) in deal.features" :key="index" variant="light-secondary-v2" class="light-secondary-v2 mr-1 mb-1">
            {{$t('creator.features').find((feature_translation) => feature_translation.value === feature.title).text}}
          </b-badge>
        </div>
      </b-col>
    </b-form-radio-group>
    <div v-if="options.length > 0" class="w-100">
      <div class="w-100 text-left mt-3">
        <strong class="my-1 text-left">Options</strong>
        <p>Additional services that can be added to the selected Deal. Choose one or more.</p>
      </div>
      <b-col 
        class="card-radion-button-steps col-12 mb-1" 
        v-for="(option, index) in options" 
        :key="index" 
        @click="getActiveCheckbox(index)"
        ref="card_checkbox_button"
      >
        <div class="background-left-proposal-checkboxs"/>
        <div class="text-left text-gray-600">
          <div class="float-right d-flex">
            <strong class="mr-1">${{utils.separatebycomma(option.price)}}</strong>
            <b-form-checkbox :value="index" v-model="checked_option"></b-form-checkbox>
          </div>
          <strong class="w-100 mb-1 d-block">{{option.title}}</strong>
          <p class="d-flex">
            <span class="d-flex align-items-center mr-1">
              <feather-icon icon="CalendarIcon" class="mr-05"/>
              {{option.days_delivery}} Days delivery
            </span>
          </p>
          <p>{{option.description}}</p>
        </div>
      </b-col>
    </div>

    <div class="w-100 text-left mt-3" v-if="requests.length > 0">
      <strong class="my-1 text-left">Seller Request</strong>
      <p>Preview of any questions the Seller may need answered prior to completing your Listing.</p>

      <b-col class="card-radion-button-steps col-12 mb-1" v-for="(request, index) in requests" :key="index"  v-b-toggle="`collapse_${index}`">
        <div class="background-left-proposal"/>
        <div class="text-left text-gray-600">
          <div class="d-flex justify-content-between align-items-center">
            <b-icon :icon="getIconsRequest(request.type_question)" class="mr-05"></b-icon>
            <strong class="w-100 d-block">{{index + 1}}. {{request.question}}</strong>
            <feather-icon icon="PlusIcon" size="22" class="cursor-pointer" v-if="request.options.length > 0"></feather-icon>
          </div>
          <b-collapse :id="`collapse_${index}`" class="pl-3">
            <div v-for="(option, index) in request.options" :key="index">
              <strong>{{String.fromCharCode(index + 65)}}.</strong>
              {{option.title}}
            </div>
          </b-collapse>
        </div>
      </b-col>
    </div>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckbox,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue';
import utils from '@/libs/utils';

export default {
  name: 'dealsOptionsRequests',
  components: {
    BRow,
    BBadge,
    BCol,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    deals: {
      type: Array,
    },
    options: {
      type: Array,
    },
    requests: {
      type: Array,
    },
  },
  data() {
    return {
      selected_deal_radio: 'deal_0',
      selected_deal: this.deals[0],
      checked_option: [],
      utils,
    }
  },
  created() {
    setTimeout(() => {
      this.getActiveDeal(this.deals[0], 0);
    }, 200);
  },
  methods: {
    getIconsRequest(type) {
      if (type === 'free_text') return 'textarea-t';
      if (type === 'radio') return 'ui-radios';
      if (type === 'checkbox') return 'ui-checks';
      if (type === 'media') return 'file-earmark-arrow-up';
    },
    getActiveDeal(deal, index) {
      this.clearActive();
      this.$refs.card_radio_button[index].classList.add('active-class-button-radio');
      this.selected_deal = deal;
      this.selected_deal_radio = `deal_${index}`
    },
    getActiveCheckbox(index) {
      if (this.checked_option.find(i => i === index) !== undefined) {
        this.checked_option = this.checked_option.filter((i) => i !== index)
      } else {
        this.checked_option.push(index);
      }
      this.$refs.card_checkbox_button[index].classList.toggle('active-class-button-checkbox')
    },
    clearActive() {
      for (let index = 0; index < this.deals.length; index++) {
        this.$refs.card_radio_button[index].classList.remove('active-class-button-radio')
      }
    },
  }
}
</script>

<style>
.card-radion-button-steps {
  border-radius: 1em !important;
  background-color: white;
  overflow: hidden !important;
  padding: 2em;
  border: 0.0625rem solid rgba(222,226,230,.7); 
  position: relative;
}
.card-radion-button-steps:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  cursor: pointer;
  transition: 500ms;
}
.background-left-proposal-radios, .background-left-proposal-checkboxs {
  background-color: #dfdddd;
  width: 10px;
  height: 420px;
  position: absolute;
  top: 0;
  left: 0px;
}
.no-cursor-pointer {
  cursor: initial !important;
}
.mr-05 {
  margin-right: 0.5em;
}
.text-gray-600 {
  color: #6c757d !important;
}
.light-secondary-v2 {
  background-color: #EFF3F6;
  color: #718096;
  font-weight: 400;
}
.active-class-button-radio {
   box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.active-class-button-radio > .background-left-proposal-radios {
  background-color: #7367f0 !important;
}
.active-class-button-checkbox {
   box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.active-class-button-checkbox > .background-left-proposal-checkboxs {
  background-color: #7367f0 !important;
}
</style>