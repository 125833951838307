<template>
  <div class="px-1">   
    <b-row class="header-preview-steps">
      <b-col :class="`text-center ${getColumnIndex(index)} p-0 p-02`" v-for="(image, index) in data_header.images.slice(0,5)" :key="index">
        <b-avatar :src="utils.getAssetUrl(image.media)" square class="avatar-preview-header-steps"></b-avatar>
      </b-col>
      <div class="button-see-more-images">
        <b-button v-if="data_header.images.length > 5" variant="light" disabled>
          See all {{data_header.images.length}} images
        </b-button>
      </div>
    </b-row>
    <b-row class="mt-2">
      <h2 class="w-100 text-left">{{data_header.title}}</h2>
      <div class="d-block">
        <b-badge variant="light-secondary-v2" class="light-secondary-v2 mr-1">{{getTranslateType(data_header.work_type)}}</b-badge>
        <b-badge variant="light-secondary-v2" class="light-secondary-v2">{{getTranslateContent(data_header.content_type)}}</b-badge>
      </div>
      <p class="w-100 text-left mt-1">{{data_header.description}}</p>
    </b-row>
  </div>  
</template>

<script>
import {
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BButton,
} from 'bootstrap-vue';
import utils from '@/libs/utils';

export default {
  name: 'headerPreviewSteps',
  components: {
    BRow,   
    BCol,
    BAvatar,
    BBadge,
    BButton,
  },
  data() {
    return {
      utils,
    }
  },
  props: {
    data_header: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  methods: {
    getColumnIndex(index) {
      let class_col = ''
      if (this.data_header.images.length > 5) {
        switch (index) {
        case 0:
          class_col = 'col-4'
          break;
        case 1:
          class_col = 'col-2'
          break;
        case 2:
          class_col = 'col-2'
          break;
        case 3:
          class_col = 'col-2'
          break;
        case 4:
          class_col = 'col-2'
          break;
        }
        return class_col;
      }
    },
    getTranslateType(text) {
      return this.$t(`creator.type_of_work`).find((type) => type.value === text).text;
    },
    getTranslateContent(text) {
      return this.$t(`creator.focus_in_${this.data_header.work_type}`).find((type) => type.value === text).text;
    }
  }
}
</script>
<style>
.avatar-preview-header-steps {
  width: 100%;
  height: 100%;
  max-height: 380px;
  object-fit: cover;
}
.header-preview-steps {
  height: 380px !important;
  position: relative;
}
.p-02 {
  padding: 0.25em !important;
}
.button-see-more-images {
  position: absolute;
  right: 2em;
  z-index: 10;
  bottom: 2em;
  background-color: red;
}
.light-secondary-v2 {
  background-color: #e8e8e8;
  color: #718096;
  font-weight: 400;
}
</style>
